import React from 'react';
import loadable from '@loadable/component';

import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';
import PreviewResolverPage from '../containers/PreviewResolverPage/PreviewResolverPage';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';
import { routingLookUps } from '../util/routeConstants';

const pageDataLoadingAPI = getPageDataLoadingAPI();
const AboutPage = loadable(() =>
  import(/* webpackChunkName: "AboutPage" */ '../containers/AboutPage/AboutPage')
);
const ArticlePage = loadable(() =>
  import(/* webpackChunkName: "ArticlePage" */ '../containers/ArticlePage/ArticlePage')
);
const ArticlesPage = loadable(() =>
  import(/* webpackChunkName: "ArticlesPage" */ '../containers/ArticlesPage/ArticlesPage')
);
const AnglersPage = loadable(() =>
  import(/* webpackChunkName: "AboutPage" */ '../containers/AnglersPage/AnglersPage')
);
const BookmarkedListingsPage = loadable(() =>
  import(
    /* webpackChunkName: "AbBookmarkedListings" */ '../containers/BookmarkedListingsPage/BookmarkedListingsPage'
  )
);
const AuthenticationPage = loadable(() =>
  import(
    /* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'
  )
);
const CorporateandLargeGroupsPage = loadable(() =>
  import(
    /* webpackChunkName: "AboutPage" */ '../containers/CorporateandLargeGroupsPage/CorporateandLargeGroupsPage'
  )
);
const CustomPrivacyPolicyPage = loadable(() =>
  import(
    /* webpackChunkName: "AboutPage" */ '../containers/CustomPrivacyPolicyPage/CustomPrivacyPolicyPage'
  )
);
const CustomTermsOfServicePage = loadable(() =>
  import(
    /* webpackChunkName: "AboutPage" */ '../containers/CustomTermsOfServicePage/CustomTermsOfServicePage'
  )
);
const CheckoutPage = loadable(() =>
  import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage')
);
const CheckoutPageV1 = loadable(() =>
  import(/* webpackChunkName: "CheckoutPageV1" */ '../containers/CheckoutPageV1/CheckoutPage')
);
const CMSPage = loadable(() =>
  import(/* webpackChunkName: "CMSPage" */ '../containers/CMSPage/CMSPage')
);
const ContactDetailsPage = loadable(() =>
  import(
    /* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage'
  )
);
const EditListingPage = loadable(() =>
  import(/* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage')
);
const EditArticlePage = loadable(() =>
  import(/* webpackChunkName: "ArticlesPage" */ '../containers/EditArticlePage/EditArticlePage')
);
const EmailVerificationPage = loadable(() =>
  import(
    /* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'
  )
);
const GiftCardsPage = loadable(() =>
  import(/* webpackChunkName: "GiftCardsPage" */ '../containers/GiftCardsPage/GiftCardsPage')
);
const GiftCardsFormPage = loadable(() =>
  import(
    /* webpackChunkName: "GiftCardsFormPage" */ '../containers/GiftCardsFormPage/GiftCardsFormPage'
  )
);
const GiftCardsTrackingPage = loadable(() =>
  import(
    /* webpackChunkName: "GiftCardsTrackingPage" */ '../containers/GiftCardTrackingPage/GiftCardsTrackingPage'
  )
);
const InboxPage = loadable(() =>
  import(/* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage')
);
const LandingPage = loadable(() =>
  import(/* webpackChunkName: "LandingPage" */ '../containers/LandingPage/LandingPage')
);
const LandownersPage = loadable(() =>
  import(/* webpackChunkName: "LandownersPage" */ '../containers/LandownersPage/LandownersPage')
);
const ListingPageCoverPhoto = loadable(() =>
  import(
    /* webpackChunkName: "ListingPageCoverPhoto" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCoverPhoto'
  )
);
const ListingPageCarousel = loadable(() =>
  import(
    /* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCarousel'
  )
);
const ListingPageCoverPhotoV1 = loadable(() =>
  import(
    /* webpackChunkName: "ListingPageCoverPhotoV1" */ /* webpackPrefetch: true */ '../containers/ListingPageV1/ListingPageCoverPhoto'
  )
);
const ListingPageCarouselV1 = loadable(() =>
  import(
    /* webpackChunkName: "ListingPageCarouselV1" */ /* webpackPrefetch: true */ '../containers/ListingPageV1/ListingPageCarousel'
  )
);
const ManageListingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'
  )
);
const ManageArticlesPage = loadable(() =>
  import(
    /* webpackChunkName: "ManageArticlesPage" */ '../containers/ManageArticlesPage/ManageArticlesPage'
  )
);
const MyOrdersPage = loadable(() =>
  import(/* webpackChunkName: "MyOrdersPage" */ '../containers/MyOrdersPage/MyOrdersPage')
);
const PasswordChangePage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage'
  )
);
const PasswordRecoveryPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'
  )
);
const PasswordResetPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'
  )
);
const PaymentMethodsPage = loadable(() =>
  import(
    /* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage'
  )
);
const PrivacyPolicyPage = loadable(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage'
  )
);
const ProfilePage = loadable(() =>
  import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage')
);
const ProfileSettingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage'
  )
);
const SearchPageWithMap = loadable(() =>
  import(
    /* webpackChunkName: "SearchPageWithMap" */ /* webpackPrefetch: true */ '../containers/SearchPage/SearchPageWithMap'
  )
);
const SearchPageWithGrid = loadable(() =>
  import(
    /* webpackChunkName: "SearchPageWithGrid" */ /* webpackPrefetch: true */ '../containers/SearchPage/SearchPageWithGrid'
  )
);
const StripePayoutPage = loadable(() =>
  import(
    /* webpackChunkName: "StripePayoutPage" */ '../containers/StripePayoutPage/StripePayoutPage'
  )
);
const TermsOfServicePage = loadable(() =>
  import(
    /* webpackChunkName: "TermsOfServicePage" */ '../containers/TermsOfServicePage/TermsOfServicePage'
  )
);
const TransactionPage = loadable(() =>
  import(/* webpackChunkName: "TransactionPage" */ '../containers/TransactionPage/TransactionPage')
);
const TransactionPageV1 = loadable(() =>
  import(
    /* webpackChunkName: "TransactionPage" */ '../containers/TransactionPageV1/TransactionPage'
  )
);
const VariablePricingPage = loadable(() =>
  import(
    /* webpackChunkName: "VariablePricingPage" */ '../containers/VariablePricingPage/VariablePricingPage'
  )
);
const DiscountCodePage = loadable(() =>
  import(
    /* webpackChunkName: "DiscountCodePage" */ '../containers/DiscountCodePage/DiscountCodePage'
  )
);
const GoogleCalendarPage = loadable(() =>
  import(
    /* webpackChunkName: "GoogleCalendarPage" */ '../containers/GoogleCalendarPage/GoogleCalendarPage'
  )
);
const SeasonPassPage = loadable(() =>
  import(/* webpackChunkName: "SeasonPassPage" */ '../containers/SeasonPassPage/SeasonPassPage')
);
const AssignGiftCardPage = loadable(() =>
  import(
    /* webpackChunkName: "AssignGitCardPage" */ '../containers/AssignGiftCardPage/AssignGiftCardPage'
  )
);

const FeatureControlPage = loadable(() =>
  import(
    /* webpackChunkName: "FeatureControlPage" */ '../containers/FeatureControlPage/FeatureControlPage'
  )
);

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() =>
  import(/* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage')
);

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'GoogleCalendarPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = layoutConfig => {
  const SearchPage =
    layoutConfig.searchPage?.variantType === 'map' ? SearchPageWithMap : SearchPageWithGrid;
  const ListingPage =
    layoutConfig.listingPage?.variantType === 'carousel'
      ? ListingPageCarousel
      : ListingPageCoverPhoto;

  const ListingPageV1 =
    layoutConfig.listingPage?.variantType === 'carousel'
      ? ListingPageCarouselV1
      : ListingPageCoverPhotoV1;

  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/p/:pageId',
      name: 'CMSPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/edit-article/:id',
      name: 'EditArticlePage',
      component: EditArticlePage,
      loadData: pageDataLoadingAPI.EditArticlePage.loadData,
    },
    {
      path: '/articles/:id',
      name: 'ArticlePage',
      component: ArticlePage,
      loadData: pageDataLoadingAPI.ArticlePage.loadData,
    },
    {
      path: '/anglers',
      name: 'AnglersPage',
      component: AnglersPage,
      loadData: pageDataLoadingAPI.AnglersPage.loadData,
    },
    {
      path: '/articles',
      name: 'ArticlesPage',
      component: ArticlesPage,
      loadData: pageDataLoadingAPI.ArticlesPage.loadData,
    },
    {
      path: '/bookmarked-listings',
      name: 'BookmarkedListingsPage',
      component: BookmarkedListingsPage,
      loadData: pageDataLoadingAPI.BookmarkedListingsPage.loadData,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    // {
    //   path: '/gift-cards',
    //   name: 'GiftCardsPage',
    //   component: GiftCardsPage,
    // },
    // {
    //   path: '/product/gift-card',
    //   name: 'GiftCardsFormPage',
    //   component: GiftCardsFormPage,
    //   auth: true,
    //   authPage: 'LoginPage',
    //   loadData: pageDataLoadingAPI.GiftCardFormPage.loadData,
    // },
    {
      path: '/corporate-and-large-group',
      name: 'CorporateandLargeGroupsPage',
      component: CorporateandLargeGroupsPage,
      loadData: pageDataLoadingAPI.CorporateandLargeGroupsPage.loadData,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/listing/:slug',
      name: 'ListingPageWithSlug',
      component: props => {
        const findListing = routingLookUps.find(routingLookUp => {
          return routingLookUp.WebsiteURL === props.location.pathname;
        });
        if (typeof window !== 'undefined') {
          if (findListing) {
            window.location.href = `${findListing.WebsiteURL.replace('/listing/', '/l/')}/${findListing.LiveId}`;
          } else {
            window.location.href = '/'
          }
        }
        return null;
      },
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/v1/:slug/:id',
      name: 'ListingPageV1',
      component: ListingPageV1,
      loadData: pageDataLoadingAPI.ListingPageV1.loadData,
    },
    {
      path: '/privacy-policy',
      name: 'CustomPrivacyPolicyPage',
      component: CustomPrivacyPolicyPage,
    },
    {
      path: '/terms-of-service',
      name: 'CustomTermsOfServicePage',
      component: CustomTermsOfServicePage,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/checkout/v1',
      name: 'CheckoutPageV1',
      auth: true,
      component: CheckoutPageV1,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
      setInitialValues: pageDataLoadingAPI.CheckoutPageV1.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/v1/:slug/:id/:variant',
      name: 'ListingPageVariantV1',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPageV1,
      loadData: pageDataLoadingAPI.ListingPageV1.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/v1/:id',
      name: 'ListingPageCanonicalV1',
      component: ListingPageV1,
      loadData: pageDataLoadingAPI.ListingPageV1.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TransactionPage.loadData(
          { ...params, transactionRole: 'customer' },
          ...rest
        ),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/order/v1/:id',
      name: 'OrderDetailsPageV1',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPageV1,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TransactionPageV1.loadData(
          { ...params, transactionRole: 'customer' },
          ...rest
        ),
      setInitialValues: pageDataLoadingAPI.TransactionPageV1.setInitialValues,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      component: props => (
        <NamedRedirect name="OrderDetailsPage" params={{ id: props.params?.id }} />
      ),
    },
    {
      path: '/sale/:id',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
    },
    {
      path: '/sale/v1/:id',
      name: 'SaleDetailsPageV1',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPageV1,
      extraProps: { transactionRole: 'provider' },
      loadData: pageDataLoadingAPI.TransactionPageV1.loadData,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      component: props => (
        <NamedRedirect name="SaleDetailsPage" params={{ id: props.params?.id }} />
      ),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/your-articles',
      name: 'ManageArticlesPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageArticlesPage,
      loadData: pageDataLoadingAPI.ManageArticlesPage.loadData,
    },
    {
      path: '/anglers-management',
      name: 'AssignGiftCardPage',
      auth: true,
      authPage: 'LoginPage',
      component: AssignGiftCardPage,
      loadData: pageDataLoadingAPI.AssignGiftCardPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/google-calendar',
      name: 'GoogleCalendarPage',
      auth: true,
      authPage: 'LoginPage',
      component: GoogleCalendarPage,
      loadData: pageDataLoadingAPI.GoogleCalendarPage.loadData,
    },
    {
      path: '/account/variable-pricing',
      name: 'VariablePricingPage',
      auth: true,
      authPage: 'LoginPage',
      component: VariablePricingPage,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    // {
    //   path: '/terms-of-service',
    //   name: 'TermsOfServicePage',
    //   component: TermsOfServicePage,
    //   loadData: pageDataLoadingAPI.TermsOfServicePage.loadData,
    // },
    // {
    //   path: '/privacy-policy',
    //   name: 'PrivacyPolicyPage',
    //   component: PrivacyPolicyPage,
    //   loadData: pageDataLoadingAPI.PrivacyPolicyPage.loadData,
    // },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },
    {
      path: '/landowners',
      name: 'LandownersPage',
      component: LandownersPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /preview endpoint
    {
      path: '/preview',
      name: 'PreviewResolverPage',
      component: PreviewResolverPage,
    },
    {
      path: '/discount-codes',
      name: 'DiscountCodePage',
      auth: true,
      authPage: 'LoginPage',
      component: DiscountCodePage,
      loadData: pageDataLoadingAPI.DiscountCodePage.loadData,
    },
    {
      path: '/my-orders',
      name: 'MyOrdersPage',
      auth: true,
      authPage: 'LoginPage',
      component: MyOrdersPage,
      loadData: pageDataLoadingAPI.MyOrdersPage.loadData,
    },
    {
      path: '/season-pass',
      name: 'SeasonPassPage',
      component: SeasonPassPage,
      loadData: pageDataLoadingAPI.SeasonPassPage.loadData,
    },

    {
      path: '/gift-card-tracking',
      name: 'GiftCardsTrackingPage',
      auth: true,
      authPage: 'LoginPage',
      component: GiftCardsTrackingPage,
      loadData: pageDataLoadingAPI.GiftCardsTrackingPage.loadData,
    },
    {
      path: '/feature-control',
      name: 'FeatureControlPage',
      auth: true,
      admin: true, // only admin users can view this page
      authPage: 'LoginPage',
      component: FeatureControlPage,
      loadData: pageDataLoadingAPI.FeatureControlPage.loadData,
    },
  ];
};

export default routeConfiguration;
